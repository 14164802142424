
export interface IManagement {
    readonly id: any;
    name: string
    description: string
}

export class Management {
    public id!: any;
    public _method!: string;
    public client_id!: string;
    public contract_expiry!: string;
    public contract_value!: any;
    public installation_date!: string;
    public service_contract_value!: any;
    public additional!: string;

}

