
import { BoxManagementIndexPage } from './index.box';
import { CloudManagementIndexPage } from './index.cloud';
import { DemoManagementIndexPage } from './index.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class ManagementIndex extends platform.mixins(environment.nexus, {
    box: BoxManagementIndexPage,
    cloud: CloudManagementIndexPage,
    demo: DemoManagementIndexPage
}) { }
