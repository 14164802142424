import { ManagementEditPage } from './edit';
import { widget } from '@cems-eco/core/src/utils';

export class DemoManagementEditPage extends ManagementEditPage {

    mounted() {
        this.management = {
            id: '1',
            _method: '',
            client_id: 'client',
            contract_expiry: '2024-09-10',
            installation_date: '2021-09-10',
            contract_value: '',
            service_contract_value: '',
            additional: '',
        };
    }

    async save() {
        try {
            widget.alertSuccess('Good Job!', 'You have successfully edit this Maintenance');
            this.$router.push(`/management`);
        } catch (error) {
            console.log(error);
        }
    }
}