import { Vue } from "vue-class-component";
import managementBloc from "../../services/bloc";
import { Subscription } from 'rxjs';
import { Tab } from "@cems-eco/core/src/models";
import type { UploadInst, UploadFileInfo } from 'naive-ui';
import { ref } from "vue";
import { widget } from "@cems-eco/core/src/utils";
import { Management } from "../../services";
import moment from "moment";
import storage from "@/storage";

export class ManagementIndexPage extends Vue {
    managementBloc = managementBloc;
    management = new Management;
    tabs: Tab
    activeTabIndex: number = 0;
    uploadRef = ref<UploadInst | null>(null);

    async created() {
        this.setTabs();
    }

    setTabs() {
        this.tabs = new Tab
        this.tabs.list = [
            {
                id: 'detail',
                name: 'Details',
                icon: '',
                active: true,
                component: 'DetailsComponent'
            },
            {
                id: 'document',
                name: 'Document Upload',
                icon: '',
                active: false,
                component: 'TableComponent'
            },
        ];
    }

    onTabChanged(index: number) {
        this.activeTabIndex = index;
    }

    handleChange(data: { fileList: UploadFileInfo[] }) {
        widget.confirm('Are you sure to upload this?').then(async (result) => {
            try {
                if (!result.isConfirmed) return;

                this.uploadRef.value?.submit()
                widget.alertSuccess('Good Job!', 'Your file has been uploaded.');
            } catch (error) {
                widget.alertError(error);
            }
        })
    }

    updateDatetimeInput() {
        if (this.management.contract_expiry)
            this.management.contract_expiry = moment(this.management.contract_expiry).format('yyyy-MM-DD');
        if (this.management.installation_date)
            this.management.installation_date = moment(this.management.installation_date).format('yyyy-MM-DD');
    }

    async checkPermission() {
        const permission = await storage.get<string[]>(storage.PERMISSION);
        if (this.management === null) {
            if (!permission.includes("Create Management")) {
                widget.alert('Oopps!', 'Management did not create yet. Please contact administrator', 'error');
                this.$router.back();
            } else {
                throw new Error("");
            }
        }
    }

}

