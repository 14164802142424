import { MonitorIndexPage } from './index';
import { Options } from 'vue-class-component';
import components from "../../components/component";
import { orderBy } from "lodash";

@Options({
    components: components
})
export class BoxMonitorIndexPage extends MonitorIndexPage {
}

