import { ManagementIndexPage } from ".";
import { ElLoading } from "element-plus";
import storage from "@/storage";
import { widget } from "@cems-eco/core/src/utils";
import { Options } from "vue-class-component";
import component from "../../components/component";


@Options({
    components: component,
})
export class BoxManagementIndexPage extends ManagementIndexPage {

    async mounted() {
        const loader = ElLoading.service({ lock: true });

        try {
            const site = storage.getSync<any>(storage.SITE);
            this.management = await this.managementBloc.getManagementByClient(site.client_id);
            this.updateDatetimeInput();
            await this.checkPermission();
            
            loader.close();
        } catch (error) {
            loader.close();
            await widget.alert('Oopps!', 'Something went wrong. Please contact administrator', 'error');
            this.$router.back();
        }
    }

}

