
import { BoxManagementEditPage } from './edit.box';
import { CloudManagementEditPage } from './edit.cloud';
import { DemoManagementEditPage } from './edit.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class ManagementEdit extends platform.mixins(environment.nexus, {
    box: BoxManagementEditPage,
    cloud: CloudManagementEditPage,
    demo: DemoManagementEditPage
}) { }
