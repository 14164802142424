import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid mt--6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CemsInfoComponent = _resolveComponent("CemsInfoComponent")!
  const _component_CemsReading = _resolveComponent("CemsReading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CemsInfoComponent, { options: _ctx.option }, null, 8, ["options"]),
    (_openBlock(), _createElementBlock("div", {
      class: "row",
      key: _ctx.equipments.length
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipments, (equipment, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-lg-4 col-sm-6",
          key: index
        }, [
          (_openBlock(), _createBlock(_component_CemsReading, {
            key: index,
            type: "basic",
            equipment: equipment
          }, null, 8, ["equipment"]))
        ]))
      }), 128))
    ]))
  ]))
}