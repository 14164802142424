import monitorBloc from "../../services/bloc";
import { Vue } from 'vue-class-component';


export class MonitorIndexPage extends Vue {
    monitorBloc = monitorBloc
    equipments = [];
    option = {
        equipment: 0,
        sensor: 0,
    };

    async created() {
        const equipments: any = await this.monitorBloc.getEquipments();
        this.option.equipment = equipments.data.data.length; 
        for (const key in equipments.data.data) {
            const object = equipments.data.data[key];
            this.option.sensor += object.sensors.length;
        }
        this.equipments = equipments.data.data.map((row: any) => {
            return {
                id: row.id,
                name: row.name,
            };
        })
    }
}

