import { ManagementIndexPage } from ".";
import { Options } from "vue-class-component";
import component from "../../components/component";


@Options({
    components: component,
})
export class DemoManagementIndexPage extends ManagementIndexPage {

    mounted() {
        // INFO : data for demo in details component
        // this.management = {
        //     _method: '',
        //     "id": "f94bae84-9855-4ee5-8e3d-c76066babb1f",
        //     "client_id": "client",
        //     "contract_expiry": "2024-04-16",
        //     "contract_value": 2000,
        //     "installation_date": "2024-03-31",
        //     "service_contract_value": 50,
        //     "additional": '',
        // }
    }

}

