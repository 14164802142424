
import { BoxMonitorIndexPage } from './index.box';
import { CloudMonitorIndexPage } from './index.cloud';
import { DemoMonitorIndexPage } from './index.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class MonitorView extends platform.mixins(environment.nexus, {
  box: BoxMonitorIndexPage,
  cloud: CloudMonitorIndexPage,
  demo: DemoMonitorIndexPage
}){}
