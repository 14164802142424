import { MonitorIndexPage } from './index';
import { Options } from 'vue-class-component';
import components from "../../components/component";

@Options({
    components: components
})
export class CloudMonitorIndexPage extends MonitorIndexPage {
}

