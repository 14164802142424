import { ManagementEditPage } from './edit';
import { widget } from '@cems-eco/core/src/utils';
import { ElLoading } from "element-plus";

export class BoxManagementEditPage extends ManagementEditPage {

    async mounted() {
        const loader = ElLoading.service({ lock: true });

        this.management.id = this.$route.params.id
        this.management = await this.managementBloc.getManagement(this.management.id);
        this.updateDatetimeInput();

        loader.close();
    }

    async save() {
        const loader = ElLoading.service({ lock: true });
        try {
            await this.managementBloc.updateManagement(this.management);
            loader.close();
            await widget.alertSuccess('Good Job!', 'You have successfully edit this Management');
            this.$router.push(`/management`);
        } catch (error) {
            loader.close();
            widget.alertError(error);
        }
    }

}