import managementBloc from "../../services/bloc";
import { Management } from "../../services";
import { Vue, Options } from 'vue-class-component';
import component from "../../components/component";
import moment from "moment";


@Options({
    components: component,
})
export class ManagementEditPage extends Vue {
    managementId: any;
    managementBloc = managementBloc
    management = new Management;

    updateDatetimeInput() {
        if (this.management.contract_expiry)
            this.management.contract_expiry = moment(this.management.contract_expiry).format('YYYY-MM-DD');
        if (this.management.installation_date)
            this.management.installation_date = moment(this.management.installation_date).format('YYYY-MM-DD');
    }
}

