import { MonitorIndexPage } from './index';
import { Options } from 'vue-class-component';
import components from "../../components/component";

@Options({
    components: components
})
export class DemoMonitorIndexPage extends MonitorIndexPage {
    equipments: any = [];
    option: any = {
        equipment: 3,
        sensor: 12,
    };
    async created() {
        this.equipments = [
            {
                id: "1",
                name: "602 TRANSMITTER (S/N:56146)",
            },
            {
                id: "2",
                name: "602 TRANSMITTER (S/N:56146)",
            },
            {
                id: "3",
                name: "602 TRANSMITTER (S/N:56146)",
            }
        ];
    }
}

